<script>
import { Line } from '@/vue-chartjs'
import { formatDate, formatCurrency } from '@/misc/filters'

export default {
  extends: Line,
  props: {
    data: Array,
    currency: String,
    currencyFormat: {
      type: Object,
      default: () => ({})
    },
    granularity: String,
    label: String,
    color: String,
    fill: Boolean
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                displayFormats: {
                  millisecond: 'MMM D',
                  second: 'MMM D',
                  minute: 'MMM D',
                  hour: 'MMM D'
                }
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value =>
                  formatCurrency(value, this.currency, this.currencyFormat)
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title: ([item], { datasets }) => {
              const dataset = datasets[item.datasetIndex]
              const point = dataset.data[item.index]
              return formatDate(point.t, this.granularity)
            },
            label: item =>
              formatCurrency(item.yLabel, this.currency, this.currencyFormat)
          }
        }
      }
    }
  },
  watch: {
    data: {
      handler() {
        this.doRender()
      }
    }
  },
  mounted() {
    this.doRender()
  },
  methods: {
    doRender() {
      if (!this.data) return
      // Update the granularity on the x axis settings
      this.options.scales.xAxes[0].time.unit = this.granularity
      this.renderChart(
        {
          datasets: [
            {
              label: this.label,
              backgroundColor: this.color,
              borderColor: this.fill ? 'rgba(0,0,0,.1)' : this.color,
              data: this.data,
              fill: this.fill
            }
          ]
        },
        this.options
      )
    }
  }
}
</script>
